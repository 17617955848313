<template>
  <v-app>
    <div style="background-color: white; min-height: 100vh">
      <Header />
      <!-- <div></div> -->
      <div v-if="parkName == 'Echo State Park'">
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/Fish.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Perch Party Ice Fishing Tournament</p>
              <div>Jan 27, 2024</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              @click="redirectToTournament()"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              >Learn More</v-btn
            >
          </div>
        </div> -->
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/Fish.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Quadfishathon</p>
              <div>4 Park Tournament Jan 18 - Feb 2, 2025</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              @click="redirectToQuad()"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              >Learn More</v-btn
            >
          </div>
        </div> -->
      </div>
      <div v-if="parkName == 'Palisade State Park'">
        <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/Fish.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">
                Palisade State Park Annual Fishing Tournament
              </p>
              <div>May 11, 2024</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              @click="FishingClicked()"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              >Learn More</v-btn
            >
          </div>
        </div>
      </div>
      <div v-if="parkName == 'Gunlock State Park'">
        <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/icons/donation.png" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Donations</p>
              <div>
                Contribute to the maintenance and enhancement of the park.
              </div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              @click="GunlockDontaions()"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              >Learn More</v-btn
            >
          </div>
        </div>
      </div>
      <div v-if="parkName == 'Anasazi State Park Museum'">
        <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/icons/donation.png" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Donations</p>
              <div>
                Contribute to the maintenance and enhancement of the park.
              </div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              @click="AnasaziDonations()"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              >Learn More</v-btn
            >
          </div>
        </div>
      </div>
      <div v-if="parkName == 'Hyrum State Park'">
        <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/icons/donation.png" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Donations</p>
              <div>
                Contribute to the maintenance and enhancement of the park.
              </div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              @click="HyrumDonations()"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              >Learn More</v-btn
            >
          </div>
        </div>
      </div>
      <div v-if="parkName == 'East Canyon State Park'">
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/ghost-icon.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Haunted Camping</p>
              <div>Event happening November 2nd</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="hauntedClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/Fish.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Quadfishathon</p>
              <div>4 Park Tournament Jan 18 - Feb 2, 2025</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              @click="redirectToQuad()"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              >Learn More</v-btn
            >
          </div>
        </div> -->
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/egg.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">
                East Canyon State Park’s <br />
                Eggstravaganza
              </p>
              <div>April 8th, 2024 at 1:00 pm</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="eggClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
      </div>
      <div
        v-if="parkName == 'Antelope Island State Park' && bisonRoundup == true"
      >
        <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/bison.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Bison Roundup</p>
              <div>October 26th, 2024</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96a145"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="bisonClicked()"
              >Learn More</v-btn
            >
          </div>
        </div>
      </div>

      <!-- <div v-if="parkName == 'Palisade State Park'">
        <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/fishing.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">
                Palisade State Park Annual Fishing Tournament
              </p>
              <div>May 13, 2024</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="FishingClicked()"
              >Learn More</v-btn
            >
          </div>
        </div>
      </div> -->
      <div v-if="parkName == 'Great Salt Lake State Park'">
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/paintbrush.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Paint Night at the Park</p>
              <div>Event happening April 22-23</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="paintNightClicked()"
              >View Tickets</v-btn
            >
          </div>
        </div> -->
        <!-- <div class="eventLink" id="green-mtn" style="background-color: #a5c5dc">
          <div class="eventIcon">
            <img src="../assets/images/hiker.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">First Day Hike</p>
              <div>Jan 1st at 8:30 am</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96a145"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="hikeClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
        <div class="eventLink" style="background-color: #a5c5dc">
          <div class="eventIcon">
            <img src="../assets/images/mira-icon.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Mirabilite Tours</p>
              <div>January 2025</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#4F8AB3"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="mirabiliteClicked()"
              >Learn More</v-btn
            >
          </div>
        </div>
        <!-- <div class="eventLink" id="blue-mtn" style="background-color: #a5c5dc">
          <div class="eventIcon">
            <img src="../assets/images/binoculars.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Bird Watching Tour</p>
              <div>May 12th, 13th, & 28th at 8:00 am</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#4F8AB3"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="birdClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/moon_icon.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Full Moon Walks</p>
              <div>
                Event happening June 21, July 21, Aug 19, Sept 17, and Oct 16.
              </div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96a145"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="moonWalkClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
        <!-- <div class="eventLink beige" id="">
          <div class="eventIcon">
            <img src="../assets/images/pioneer-wagon.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Pioneer Games</p>
              <div>September 9th at 10 am to 2 pm</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#A98655"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="pioneerGamesClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
        <!-- <div class="eventLinkBlue" id="">
          <div class="eventIcon">
            <img src="../assets/images/campfire-icon.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Campfire Stories</p>
              <div>
                Event happening Sept 15, Sept 22, Oct 13, Oct 20, and Oct 21.
              </div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#4F8AB3"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="campfireStoriesClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
        <!-- <div v-if="parkName == 'Palisade State Park'">
          <div class="eventLink" id="green-mtn">
            <div class="eventIcon">
              <img src="../assets/images/paintbrush.svg" id="bannerImage" />
              <div id="banner">
                <p class="eventText">Paint Night at the Park</p>
                <div>Event happening April 22-23</div>
              </div>
            </div>
            <div class="buttonContainer">
              <v-btn
                rounded
                bold
                color="#96A145"
                class="eventButton"
                style="
                  text-transform: capitalize;
                  color: white;
                  font-family: 'boldRoboto';
                "
                @click="paintNightClicked()"
                >View Tickets</v-btn
              >
            </div>
          </div>
        </div> -->

        <!-- <div class="eventLinkBlue">
          <img src="../assets/images/mira-icon.svg" id="bannerImage" />
          <div id="banner">
            <p class="eventText">Mirabilite Tours</p>
            <div>January 2024</div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#4F8AB3"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="mirabiliteClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
      </div>

      <div v-if="parkName == 'Lost Creek State Park'">
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/Fish.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Quadfishathon</p>
              <div>4 Park Tournament Jan 18 - Feb 2, 2025</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              @click="redirectToQuad()"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              >Learn More</v-btn
            >
          </div>
        </div> -->
      </div>

      <!-- paddling with a ranger event -->

      <div v-if="parkName == 'Rockport State Park'">
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/Fish.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Quadfishathon</p>
              <div>4 Park Tournament Jan 18 - Feb 2, 2025</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96A145"
              class="eventButton"
              @click="redirectToQuad()"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              >Learn More</v-btn
            >
          </div>
        </div> -->
        <!-- <div class="eventLink" id="green-mtn">
          <div class="eventIcon">
            <img src="../assets/images/kayaking.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">
                Paddling and Birding Adventure with a Ranger
              </p>
              <div>Event happening August 27th</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#96a145"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="paddlingRangerClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
      </div>

      <!-- fantasy at the bay -->

      <!-- <div v-if="parkName == 'Willard Bay State Park'"> -->
      <!-- <div class="eventLink gradBack">
          <img
            src="../assets/images/lights-icon.svg"
            id="bannerImage"
            class="lightsicon"
            style="padding-right: 10px"
          />
          <div id="banner">
            <p
              class="eventText"
              style="color: #000000 !important; font-size: 18px"
            >
              Fantasy at the Bay
            </p>
            <div style="color: #000000 !important; font-size: 18px">
              Drive through light show
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn
              rounded
              bold
              color="#0C500F"
              class="eventButton"
              style="
                text-transform: capitalize;
                color: white;
                font-family: 'boldRoboto';
              "
              @click="FantasyClicked()"
              >Learn More</v-btn
            >
          </div>
        </div> -->
      <!-- </div> -->
      <!-- quail creek triathlon -->
      <!-- <div v-if="parkName == 'Quail Creek State Park'">
        <div v-if="triathlon">
          <div class="eventLink">
            <img src="../assets/images/quailcreek-swim.svg" id="bannerImage" />
            <div id="banner">
              <p class="eventText">Southern Utah Triathlon</p>
              <div>
                Purchase entrance passes for spectators and participants.
              </div>
            </div>
            <div class="buttonContainer">
              <v-btn
                rounded
                bold
                color="#96a145"
                class="eventButton"
                style="
                  text-transform: capitalize;
                  color: white;
                  font-family: 'boldRoboto';
                "
                @click="quailTriathlonClicked()"
                >Learn More</v-btn
              >
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div v-if="parkName == 'Fremont Indian State Park and Museum'">
        <div v-if="carShow">
          <div class="eventLink">
            <v-icon>mdi-car</v-icon>
            <div class="eventInfo">
              <img src="../assets/images/car_icon.svg" id="bannerImage2" />
              <div id="banner">
                <p class="eventText">Clear Creek Canyon Car Show</p>
                <div>September 17, 2024</div>
              </div>
            </div>
            <div class="buttonContainer">
              <v-btn
                rounded
                bold
                color="#96a145"
                class="eventButton"
                style="
                  text-transform: capitalize;
                  color: white;
                  font-family: 'boldRoboto';
                "
                href="https://www.parkspassutah.com/products/clear-creek-car-entry-fee"
                >Register Car</v-btn
              >
            </div>
          </div>
        </div>
      </div> -->

      <div class="page-wrap">
        <!-- <div v-if="parkName == 'Annual Passes (Not Digital)'">
          <v-breadcrumbs
            divider="/"
            :items="breadcrumbs"
            style="padding: 8px 0px"
          ></v-breadcrumbs>
          <div id="apology">We apologize for the inconvenience.</div>
          <div id="apology2">2024 Annual Passes will be available soon.</div>
          <div id="apology3">
            If you would like to purchase an Annual State Park Pass for 2024,
            they are available at a discounted rate at any Utah State Park.
          </div>
        </div> -->
        <div>
          <div v-if="dayBanner" class="dayBanner">
            <img
              src="../assets/icons/blueInfo.png"
              style="
                display: inline-block;
                vertical-align: middle;
                margin: 0px 12px;
                height: 24px;
              "
            />
            <!-- <div
              v-if="
                (parkName == 'Physical Annual Pass') |
                  (parkName == 'Digital Annual Pass') |
                  (parkName == 'Annual Pass')
              "
            >
              <p class="Attention">
                Attention: Pass expires December 31st, 2024.
              </p>
            </div> -->
            <div v-if="parkName == 'Snow Canyon State Park'">
              <p class="Attention">
                Attention: Pass is good at Snow Canyon for the day of purchase
                only. Pass includes the Cinder Cone, Whiterocks, Gila, Scenic
                Overlook, and Chuckwalla parking lots in addition to the main
                canyon.
              </p>
            </div>
            <div v-else>
              <p class="Attention">
                Attention: Pass is good for the day of purchase
                <span
                  v-if="parkName == 'Goblin Valley State Park'"
                  class="Attention"
                  >and the following day</span
                >
                only!
              </p>
            </div>
            <img
              src="../assets/icons/blueX.png"
              style="
                float: right;
                display: inline-block;
                margin: 12px 16px;
                cursor: pointer;
                height: 24px;
                margin-left: auto;
              "
              @click="dayBanner = false"
            />
          </div>

          <v-breadcrumbs
            divider="/"
            :items="breadcrumbs"
            style="padding: 8px 0px"
          ></v-breadcrumbs>
          <div class="parkTitleWrap">
            <h1
              v-if="parkName == 'State Park Annual Pass'"
              style="margin-top: 0px"
            >
              Utah State Park Annual Pass
            </h1>
            <h1 v-else style="margin-top: 0px">{{ parkName }}</h1>
            <h1
              v-if="parkName == 'State Park Annual Pass'"
              class="resonly"
              style="margin-top: 10px"
            >
              Utah Residents Only
            </h1>
            <div v-if="parkName == 'State Park Annual Pass'">
              <div class="delivery">Estimated delivery 7-10 business days.</div>
              <div class="delivery">
                Need a pass sooner? Buy a pass and test out our new
                <div id="eta">
                  <a href="https://parkspass.utah.gov/app" id="eta"
                    >digital annual pass app.</a
                  >
                </div>
              </div>
            </div>
            <div v-if="parkName == 'Annual Pass'">
              <div class="delivery">
                Would you like a digital or physical annual pass?
              </div>
              <v-radio-group v-model="radios" mandatory>
                <v-radio
                  class="checkbox"
                  :label="'Digital (app download required) (COMING SOON)'"
                  value="digital"
                  color="#96A145"
                  disabled
                ></v-radio>
                <v-radio
                  class="checkbox"
                  :label="'Physical'"
                  value="physical"
                  color="#96A145"
                ></v-radio>
              </v-radio-group>
              <div style="display: flex; justify-content: flex-end">
                <button class="annButton" @click="goToPass()">Continue</button>
              </div>
            </div>
            <div v-if="parkName == 'Digital Annual Pass'">
              <div class="delivery orange-text">Only Good with Mobile App</div>
              <div class="delivery2">
                <p>
                  Download the Utah State Parks app to receive the pass after
                  purchase. This does not entitle the purchaser to a physical
                  pass.
                </p>
              </div>
            </div>
            <div
              v-if="
                (parkName != 'State Park Annual Pass') &
                (parkName != 'Digital Annual Pass') &
                (parkName != 'Annual Pass')
              "
            >
              <h3 class="passTitle">Day-Use Passes</h3>
            </div>
            <h2>{{ currentParkMeta }}</h2>
          </div>
          <div
            id="shopifyCollectionWrap"
            class="shopifyCollectionWrap"
            v-if="parkName != 'Annual Pass'"
          >
            <!-- <div id="loader"></div> -->
            <div
              id="collection-component-1675198796607"
              class="collectionDiv"
            ></div>
            <div
              id="park-closed"
              style="width: 100%; display: none; text-align: center"
            >
              <img
                alt="Park Closed"
                src="../assets/images/temorarily-unavailable.png"
                class="park-unavailable"
              />
            </div>
            <div
              id="onlineUnavailable"
              style="width: 100%; display: none; text-align: center"
            >
              <img
                alt="Online Passes Not Available"
                class="park-unavailable"
                src="../assets/images/online-passes-not-available.png"
              />
              <div style="display: flex; justify-content: center">
                <p style="width: 400px">
                  Due to heightened visitation surrounding the solar eclipse,
                  park entry cannot be guaranteed and we are not selling online
                  passes today at {{ parkName }}. Passes may be available for
                  purchase at the park.
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            <v-divider class="divider" />
            <p class="passTitle">What's the difference?</p>
            <p class="passType" style="margin-top: 20px; margin-bottom: 7px">
              Digital Pass
            </p>
            <p class="passText">
              The digital pass is made by collecting your license plate number.
              The pass will be stored on a mobile app on your device. You must
              download the Utah State Parks app to receive the pass after
              purchase. You will show a QR code from the app upon arriving to
              any Utah state park.
            </p>

            <p class="passType" style="margin-top: 20px; margin-bottom: 7px">
              Physical Pass
            </p>
            <p class="passText">
              This is a physical card and lanyard that you hang on the rearview
              mirror for entry into the park. Wait 7-10 days for a physical pass
              to arrive in the mail.
            </p>
          </div>

          <!-- <div v-if="parkName=='Great Salt Lake State Park'|| parkName=='Test Park' ">
                  <v-divider class="divider"/>
                  <p class="passTitle">Tour Information</p>
                  <h4 class="GSLheader">Required Fees</h4>
                  <p class="passText">Tour fee does not cover entry into the park. There is a $5 per vehicle entry fee to enter Great Salt Lake State Park + a $3 per person tour fee.</p>
                  <h4 class="GSLheader">Description</h4>
                  <p class="passText">Are you interested in visiting the Great Salt Lake State Park to see the Mirabilite Formations? Now is your chance to attend a Ranger lead tour about these formations. These salt mineral deposits can only be seen during specific winter conditions and we do not know how long these formations. </p>
                  <h4 class="GSLheader">Date</h4>
                  <p class="passText">Feb 21, 2022</p>
                  <h4 class="GSLheader">Max capacity</h4>
                  <p class="passText">A max capacity of 25 People per tour. Tours will be every hour from 10-3 beginning on the back patio of the Visitor Center.</p>
                  <h4 class="GSLheader">What to bring</h4>
                  <p class="passText">Waterproof shoes are recommended for tours.</p>
              </div> -->

          <v-divider class="divider" />

          <!-- Bear Lake && Jordanelle Specific -->
          <div
            v-if="
              parkName == 'Bear Lake State Park' ||
              parkName == 'Jordanelle State Park'
            "
          >
            <p class="passTitle">Pass Information</p>
            <div v-for="(location, index) in parkOptions" v-bind:key="index">
              <div class="parkOption" @click="parkOptionSelected(location)">
                <h3 class="parkOptionTitle">{{ location }}</h3>
                <img
                  class="arrow"
                  src="../assets/icons/arrow.svg"
                  :class="{ flipArrow: currentParkOption == location }"
                />

                <div class="passGrid" v-if="currentParkOption == location">
                  <div
                    v-for="(pass, i) in filteredParkOptions"
                    v-bind:key="i"
                    class="passContainer"
                  >
                    <img
                      style="
                        height: 42px;
                        margin-top: 6px;
                        margin-right: 16px;
                        width: auto;
                      "
                      :src="require(`../assets/new_icons/${pass.image}`)"
                      :alt="pass.altDescription"
                    />
                    <div class="passMargin">
                      <!-- <p
                        v-if="pass.price"
                        class="passText"
                        style="line-height: 20px"
                      >
                        ${{ pass.price }}
                      </p> -->
                      <h4 class="passType">{{ pass.passType }}</h4>
                      <p class="passText">{{ pass.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div style="height: 16px"></div> -->

            <h3 class="passTextRed">*Pass is good for day of purchase only!</h3>
            <h3 class="passTextRed">
              *Purchase does not guarantee entry if park is temporarily closed
              due to capacity being reached.
            </h3>
          </div>
          <div v-else>
            <p class="passTitle">Pass Information</p>
            <div class="passGrid">
              <div
                class="passContainer"
                v-for="(pass, index) in passList"
                v-bind:key="index"
              >
                <img
                  style="
                    height: 42px;
                    margin-right: 16px;
                    width: auto;
                    position: relative;
                    top: -2px;
                  "
                  :src="require(`../assets/new_icons/${pass.image}`)"
                  :alt="pass.image"
                />
                <div class="passMargin">
                  <!-- <p class="passText" style="line-height: 20px">
                    ${{ pass.price }}
                  </p> -->
                  <h4 class="passType">{{ pass.passType }}</h4>
                  <p class="passText">{{ pass.description }}</p>
                </div>
              </div>
            </div>
            <div v-if="parkName == 'State Park Annual Pass'">
              <div class="annual_head">Overview</div>
              <ul>
                <li>Pass is valid for 12 months from date of purchase.</li>
                <li>
                  Entitles purchaser, and up to seven guests in the same
                  private, non-commercial vehicle; or purchaser and up to three
                  walk-ins, motorcyclists, or bicyclists; to day-use admittance
                  to all Utah State Parks.
                </li>
                <li>
                  Not valid for This is the Place Heritage Park or Jordan River
                  OHV State Park.
                </li>
                <li>Not valid for special events or sponsored activities.</li>
                <li>
                  Does not cover the $2 Davis County Causeway fee at Antelope
                  Island State Park.
                </li>
                <li>
                  Pass is non-transferable and is not valid for commercial use.
                </li>
              </ul>
              <div class="annual_head">What to expect after purchase</div>
              <ul>
                <li>
                  Pass will be sent through USPS mail to your preferred mailing
                  address.
                </li>
                <li>Pass will take between 7-10 business days to arrive.</li>
                <li>
                  <a href="https://parkspass.utah.gov/app"
                    >Try our new digital annual pass app</a
                  >
                  before its official release.
                </li>
              </ul>
              <!-- <div class="annual_head">Displaying Pass</div>
              <p>
                Pass must be displayed in vehicle at all times while parked at a
                state park facility.
              </p> -->
              <div class="annual_head">Signature</div>
              <p>
                Pass is not valid until name/signature line is completed. By
                signing, pass holder agrees to all stated terms and conditions.
              </p>
              <div class="annual_head">Disclaimer</div>
              <ul>
                <li>
                  This pass does not guarantee entrance into the park if park is
                  temporarily closed due to capacity being reached.
                </li>
                <li>
                  Use or possession of a lost or stolen pass may result in
                  citation and it may be taken away.
                </li>
                <li>
                  The security of pass and vehicle is owner's responsibility.
                </li>
                <li>Duplicate or replacement passes are not issued.</li>
              </ul>
            </div>

            <div v-if="parkName == 'Digital Annual Pass'">
              <div class="annual_head">Overview</div>
              <ul>
                <li>
                  Entitles purchaser, and up to seven guests in the same
                  private, non-commercial vehicle; or purchaser and up to three
                  walk-ins, motorcyclists, or bicyclists; to day-use admittance
                  to all Utah State Parks.
                </li>
                <li>
                  Not valid for This is the Place Heritage Park or Jordan River
                  OHV State Park.
                </li>
                <li>Not valid for special events or sponsored activities.</li>
                <li>
                  Does not cover the $2 Davis County Causeway fee at Antelope
                  Island State Park.
                </li>
                <li>
                  Pass is non-transferable and is not valid for commercial use.
                </li>
              </ul>
              <div class="annual_head">What to expect after purchase</div>
              <ul>
                <li>
                  To download your digital annual pass, download the Utah State
                  Parks app from the App Store.
                </li>
              </ul>
              <div style="display: flex" class="storeButtons">
                <button class="downlaod-button grey">
                  <img src="../assets/new_icons/android.svg" alt="" />
                  ANDROID COMING SOON
                </button>
                <button class="downlaod-button">
                  <img src="../assets/new_icons/ios.svg" alt="" />
                  IOS
                </button>
              </div>
              <div class="annual_head">Disclaimer</div>
              <ul>
                <li>
                  This pass does not guarantee entrance into the park if park is
                  temporarily closed due to capacity being reached.
                </li>
                <li>
                  Use or possession of a lost or stolen pass may result in
                  citation and it may be taken away.
                </li>
                <li>
                  The security of pass and vehicle is owner's responsibility.
                  Duplicate or replacement passes are not issued.
                </li>
              </ul>
            </div>

            <div v-if="parkName == 'Deer Creek State Park'">
              <v-divider class="divider" />
              <p class="passTitle" style="margin-bottom: 16px">
                Payment Instructions Upon Arrival
              </p>
              <p>1. Take a self-pay envelope.</p>
              <p>2. Write your confirmation number on the stub.</p>
              <p>3. Tear off the stub and display it on your dashboard.</p>
              <p>4. Place the envelope in the slot provided</p>
            </div>

            <!-- <div style="height: 16px"></div> -->
            <h3
              class="passTextRed"
              v-if="parkName == 'Goblin Valley State Park'"
            >
              *Pass is good for day of purchase and the following day only!
            </h3>
            <h3
              class="passTextRed"
              v-else-if="
                (parkName != 'State Park Annual Pass') &
                (parkName != 'Digital Annual Pass') &
                (parkName != 'Annual Pass')
              "
            >
              *Pass is good for day of purchase only!
            </h3>
            <h3
              class="passTextRed"
              v-if="
                (parkName != 'State Park Annual Pass') &
                (parkName != 'Digital Annual Pass') &
                (parkName != 'Annual Pass')
              "
            >
              *Purchase does not guarantee entry if park is temporarily closed
              due to capacity being reached.
            </h3>
          </div>
          <div v-if="parkName == 'Echo State Park'">
            <v-divider class="divider" />
            <!-- <p class="passTitle" style="margin-bottom: 16px">
              Firewood Information
            </p>
            <p>1. Take a self-pay envelope.</p>
            <p>2. Write your confirmation number on the stub.</p>
            <p>3. Tear off the stub and display it on your dashboard.</p>
            <p>4. Place the envelope in the slot provided</p> -->
            <!-- <div class="d-flex">
              <img src="../assets/images/firewood.svg" />
              <div class="ml-4">
                <h4 class="passType">Firewood Information</h4>
                <p class="passText">
                  Receive wood from Camphost, or Echo General Store.
                </p>
              </div>
            </div> -->
            <p class="passTitle">Firewood Information</p>
            <div class="passGrid">
              <div class="passContainer">
                <img
                  style="
                    height: 42px;
                    margin-right: 16px;
                    width: auto;
                    position: relative;
                    top: -2px;
                  "
                  :src="require(`../assets/images/firewood.svg`)"
                />
                <div class="passMargin">
                  <!-- <p class="passText" style="line-height: 20px">
                    ${{ pass.price }}
                  </p> -->
                  <h4 class="passType">Firewood</h4>
                  <p class="">
                    Receive wood from Camphost, or Echo General Store.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="parkName == 'Goblin Valley State Park'"></div>
          <div class="shopifyBox2" v-if="ohv_details">
            <p class="passTitle">Waivers</p>
            <p class="passText">
              Sign your waiver(s) before you come to save time!
            </p>
            <p class="passText" style="margin: 16px 0px">
              *We have updated our waiver system. Waivers are now only required
              once a calendar year. New waivers accepted after 4/25/2020 will be
              good until the end of December. New Waivers are located below or
              at the park upon arrival!
            </p>
            <div>
              <a
                class="waiverLink"
                href="https://docs.google.com/forms/d/e/1FAIpQLSd74q1JodOuYaasSiayD5rcN9TSPyDMbtHB8jpsf1yi-g9nwA/viewform"
                target="_blank"
                rel="noopener noreferrer"
                style="margin-right: 32px"
                >Minor Waiver</a
              >
              <a
                class="waiverLink"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfG8ZCs_ri52FMnuDpId3FVvLBL7vWCoj2rVBgb6Dzj0sv38Q/viewform"
                target="_blank"
                rel="noopener noreferrer"
                >Adult Waiver</a
              >
            </div>
          </div>
        </div>
        <!-- Park Information -->
        <!-- <div class="shopifyBox2" style="cursor: pointer" @click="displayInfo = !displayInfo">
              <p class="passTitle" style="display: inline-block;">Park Information</p>
              <img class="arrow" style="margin-top: 8px" src="../assets/icons/arrow.svg" :class="{flipArrow: displayInfo}">
              <div v-if="displayInfo">
                <p class="passText" style="margin: 6px 0px">{{parkDescription}}</p>
                <a class="parkLink" :href="learnLink" target="_blank" rel="noopener noreferrer">Learn More About {{parkName}}</a>
              </div>
          </div> -->
      </div>
      <div v-if="parkName == 'East Canyon State Park'" class="rentals">
        <div class="rentalContent">
          <div class="passTitle">East Canyon State Park Rentals</div>
          <div class="rentalContainer">
            <img class="col1" src="../assets/images/sea-doo.png" />
            <div class="col2">
              <div class="passType">
                Rent watercraft equipment at
                <a
                  class="passType"
                  href="https://parkspass.store/collections/east-canyon-rentals"
                  >parkspass.store</a
                >
              </div>
              <div style="font-size: 14px">
                Kayaks, Canoes, Paddleboards, Boats, and Jet Skis.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"></script>
<script src="https://sdks.shopifycdn.com/js-buy-sdk/latest/shopify-buy.umd.min.js"></script>

<script>
import { collectionJson } from "../assets/ParkFiles/collectionTable";
import { collectionJsonWeekend } from "../assets/ParkFiles/collectionTableWeekend";

import { parkPassesJson } from "../assets/ParkFiles/parkPassesJson";
import { parksJson } from "../assets/ParkFiles/parks";
import Header from "../components/Header.vue";

export default {
  name: "ParkDayuse",
  components: {
    Header,
  },
  data() {
    return {
      currentPark: "",
      currentParkMeta: "",
      parkName: "",
      parkAbbr: this.$route.params.name,
      parkPasses: parkPassesJson,
      parks: parksJson,
      collectionTable: collectionJson,
      collectionTableWeekend: collectionJsonWeekend,
      shortParkName: "",
      parkLink: "",
      passList: [],
      passType: "Day-Use Passes",
      passSelected: "",
      currentPassInfo: [],
      parkOptions: [],
      currentParkOption: "",
      collectionId: "",
      holidays: [],
      learnLink: "",
      lakeWaiver: false,
      ohv_details: false,
      displayInfo: false,
      parkDescription: "",
      passesWithPlates: [],
      plateRequired: true,
      sdkReady: false,
      checkingInterval: null,
      dayBanner: true,
      dateParks: [
        "Bear Lake State Park",
        "Deer Creek State Park",
        "Jordanelle State Park",
        "Utah Lake State Park",
        "Willard Bay State Park",
        "Palisade State Park",
        "Echo State Park",
      ],
      season: "",
      breadcrumbs: [],
      myTimeout: null,
      myInterval: null,
      myIntervalCount: 0,
      moonEvent: true,
      carShow: true,
      triathlon: true,
      groupfire: true,
      digitalCheckbox: false,
      physicalCheckbox: false,
      radios: "",
      bisonRoundup: false,
    };
  },
  created() {
    this.moonEventDateCheck;
    this.triDate;
    this.groupFireDateCheck;
    // this.ShopifyBuyInit(collectionId);
    if (this.parkAbbr == "PANUAL") {
      console.log("in the annual pass");
      this.dayBanner = false;
    }
  },
  metaInfo() {
    return {
      title: this.currentPark.name + " | Day-Use Passes",
      meta: [{ name: "description", content: this.currentParkMeta }],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    });
  },

  mounted() {
    this.locationSelected(this.$route.params.name);
    console.log("loading top");
    this.$nextTick(() => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
  },
  methods: {
    passNotOnline() {
      const date = new Date();
      //const date = new Date("2024/02/08");

      var day = date.getDay();
      var month = date.getMonth() + 1;
      var fullDate = date.getDate();
      console.log("day: ", day, "month: ", month, "date: ", fullDate);
      console.log(day);

      if (month == 10 && fullDate == 14) {
        // console.log("date is 14");
        let shopifyDiv = document.getElementById(
          "collection-component-1675198796607"
        );
        shopifyDiv.style.display = "none";
        let parkClosed = document.getElementById("onlineUnavailable");
        parkClosed.style.display = "block";
      }
    },
    moonWalkClicked() {
      this.$router.push("/parks/UTGREA/moonwalk");
    },
    pioneerGamesClicked() {
      this.$router.push("/parks/UTGREA/pioneergames");
    },
    paddlingRangerClicked() {
      this.$router.push("/parks/UTROCK/paddlingwithranger");
    },
    redirectToQuad() {
      this.$router.push("/parks/quadfishathon/info");
    },
    campfireStoriesClicked() {
      this.$router.push("/parks/UTGREA/campfire");
    },
    // prehistoricClicked() {
    //   this.$router.push("/parks/UTGREA/prehistoric");
    // },
    hauntedClicked() {
      this.$router.push("/parks/UTEAST/camping/haunted");
    },
    paintNightClicked() {
      this.$router.push("/parks/UTGREA/paintnight");
    },
    eggClicked() {
      this.$router.push("/parks/UTEAST/eggstravaganza");
    },
    mirabiliteClicked() {
      this.$router.push("/parks/UTGREA/mirabilite");
    },
    quailTriathlonClicked() {
      this.$router.push("/parks/UTQUIA/quailtriathlon");
    },
    FantasyClicked() {
      this.$router.push("/parks/UTWILL/fantasyatthebay");
    },
    FishingClicked() {
      this.$router.push("/parks/UTPALI/palisadefishingreg");
    },
    birdClicked() {
      this.$router.push("/parks/UTGREA/birdwatching");
    },
    hikeClicked() {
      this.$router.push("/parks/UTGREA/hike");
    },
    bisonClicked() {
      this.$router.push("/parks/UTANTE/bisonroundup");
    },
    GunlockDontaions() {
      this.$router.push("/parks/UTGUNL/donations");
    },
    AnasaziDonations() {
      this.$router.push("/parks/UTANAS/donations");
    },
    HyrumDonations() {
      this.$router.push("/parks/UTHYRU/donations");
    },
    redirectToTournament() {
      window.location.href =
        "https://www.parkspassutah.com/products/perch-party-ice-fishing-tournament";
    },
    triDate: function () {
      console.log("in the tridate function");
      const d = new Date();
      var end = new Date("2022-10-22");
      if (d <= end) {
        this.triathlon = true;
        console.log("quail triathlon date");
      }
    },
    groupFireDateCheck() {
      const d = new Date();
      var end = new Date("2022-10-7");
      if (d <= end) {
        this.groupfire = true;
        console.log("fire date");
      } else {
        this.groupfire = false;
        console.log("fire over");
      }
    },
    moonEventDateCheck() {
      const d = new Date();
      var end = new Date("2022-10-09");
      if (d <= end) {
        this.moonEvent = true;
        console.log("moon date");
      } else {
        console.log("moon over");
      }
    },
    carShowDateCheck: function () {
      const d = new Date();
      //const d = new Date("2024/02/08");
      var end = new Date("2022-12-31");
      if (d <= end) {
        this.carShow = true;
      }
    },
    echoSetCollectionID: function () {
      const d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDay();
      var date = d.getDate();
      console.log("month", month);
      console.log("day", day);
      console.log("date", date);

      // in season with camping 4/1-5/1 and 10/1-10/31
      if (month == 4) {
        this.collectionId = "322479128729";
      }
      if (month == 5 && day == 1) {
        this.collectionId = "322479128729";
      }
      if (month == 10) {
        this.collectionId = "322479128729";
      }

      // off season
      if (month >= 11 || month <= 3) {
        this.collectionId = "322479095961";
      }

      // in season no camping 5/2-9/31
      if (month == 5 && day >= 2) {
        this.collectionId = "305086169241";
      }
      if (month >= 6 && month <= 9) {
        this.collectionId = "305086169241";
      }
    },
    bearLakeSetCollectionID: function () {
      const d = new Date(); // Get today's date
      //const d = new Date("2025-09-02T00:00:00");
      console.log("Current date:", d);

      // Function to calculate the Friday before Memorial Day in a given year
      const getFridayBeforeMemorialDay = (year) => {
        const lastDayInMay = new Date(year, 4, 31); // May 31
        const dayOfWeek = lastDayInMay.getDay(); // Day of the week for May 31 (0 = Sunday, 6 = Saturday)
        const daysToMonday = dayOfWeek >= 1 ? dayOfWeek - 1 : 6; // Days to the last Monday in May
        const memorialDay = new Date(year, 4, 31 - daysToMonday); // Last Monday in May
        return new Date(
          memorialDay.getFullYear(),
          memorialDay.getMonth(),
          memorialDay.getDate() - 3
        ); // Subtract 3 days for Friday
      };

      // Function to calculate the first Monday in September (Labor Day) of a given year
      const getLaborDay = (year) => {
        const septemberFirst = new Date(year, 8, 1); // September 1
        const dayOfWeek = septemberFirst.getDay(); // Day of the week for September 1 (0 = Sunday, 1 = Monday, ...)
        const daysToMonday = dayOfWeek === 0 ? 1 : (7 - dayOfWeek + 1) % 7; // Days to the first Monday
        return new Date(
          septemberFirst.getFullYear(),
          septemberFirst.getMonth(),
          septemberFirst.getDate() + daysToMonday
        );
      };

      // Calculate key dates
      const currentYear = d.getFullYear();
      const fridayBeforeMemorialDay = getFridayBeforeMemorialDay(currentYear);
      const laborDay = getLaborDay(currentYear);
      const dayAfterLaborDay = new Date(
        laborDay.getFullYear(),
        laborDay.getMonth(),
        laborDay.getDate() + 1
      );

      console.log("Friday Before Memorial Day:", fridayBeforeMemorialDay);
      console.log("Labor Day:", laborDay);
      console.log("Day After Labor Day:", dayAfterLaborDay);

      // Determine the season
      if (d >= new Date(currentYear, 0, 1) && d <= fridayBeforeMemorialDay) {
        console.log("Bear Lake is in Regular Season");
        this.collectionId = "318528061593";
      } else if (d > fridayBeforeMemorialDay && d <= laborDay) {
        console.log("Bear Lake is in Prime Season");
        this.collectionId = "318528127129";
      } else if (d > laborDay && d <= new Date(currentYear, 11, 31)) {
        console.log("Bear Lake is in Regular Season");
        this.collectionId = "318528061593";
      } else {
        console.log("Invalid date range");
      }
    },

    jordanelleSetCollectionID: function () {
      const d = new Date();
      //const d = new Date("2024/02/08");
      var month = d.getMonth() + 1;
      if (month >= 5 && month <= 10) {
        // May - Oct
        console.log("in season");
        this.collectionId = "323317891225";
      } else {
        // Nov - April
        this.collectionId = "318531240089";
      }
    },
    palisadeSetCollectionID: function () {
      const d = new Date();
      // const d = new Date("2024/03/03");
      var month = d.getMonth() + 1;
      console.log(month);
      //Off season Dec 1 - Feb 28th
      if (month <= 2 || month == 12) {
        this.collectionId = "319164416153";
      }
    },
    // quailSetCollectionID: function () {
    //   const d = new Date();

    //   const year = d.getFullYear();
    //   const month = d.getMonth(); // Note: January is 0, December is 11
    //   const date = d.getDate();

    //   if (year === 2024 && month === 9 && date === 28) {
    //     // October is month 9 (0-indexed)
    //     console.log("it is oct 28th");
    //     this.collectionId = "324391207065";
    //   }
    // },
    utahLakeSetCollectionID: function () {
      const d = new Date();
      //const d = new Date("2024/02/08");
      // console.log("utah date: ", d);
      var month = d.getMonth() + 1;
      // console.log("utah month: ", month);
      if (month >= 4 && month <= 10) {
        // May - Oct
        console.log("in season");
        this.collectionId = "305059627161";
      } else {
        // Nov - April
        this.collectionId = "319165300889";
      }
    },
    rockportSetCollectionID: function () {
      const d = new Date();
      //const d = new Date("2024/02/08");
      var month = d.getMonth() + 1;
      var day = d.getDay();
      //date parameters here
      if (month >= 4 && month <= 10) {
        if (month == 4) {
          if (day >= 16) {
            this.collectionId = "305060020377";
          } else {
            this.collectionId = "318531403929";
          }
        } else if (month == 10) {
          if (day <= 14) {
            this.collectionId = "305060020377";
          } else {
            this.collectionId = "318531403929";
          }
        } else {
          this.collectionId = "305060020377";
        }

        //april 16 - oct 14
        console.log("in season");
        this.collectionId = "305060020377";
      } else {
        //oct 15 - april 15
        this.collectionId = "318531403929";
      }
    },
    goblinValleySetCollectionID: function () {
      //friday - sunday in April(4) May(5) Sept(9) Oct(10)
      // const date = new Date("2022-09-12");
      const date = new Date();
      //const date = new Date("2024/02/08");

      var day = date.getDay();
      var month = date.getMonth() + 1;
      var fullDate = date.getDate();
      console.log("day: ", day, "month: ", month, "date: ", fullDate);
      console.log(day);
      if (month == 4 || month == 5 || month == 9 || month == 10) {
        if (day >= 5 || day == 0) {
          this.collectionId = 319165399193;
        }
      }
      if (month == 10 && fullDate == 14) {
        let shopifyDiv = document.getElementById(
          "collection-component-1675198796607"
        );
        shopifyDiv.style.display = "none";
        let parkClosed = document.getElementById("onlineUnavailable");
        parkClosed.style.display = "block";
      }
    },
    deerCreekSetCollectionID: function () {
      const date = new Date();
      //const date = new Date("2024-02-08");
      const start = new Date("2024-10-16");
      const end = new Date("2024-04-15");
      //oct 16 - april 15
      if (date >= start || date <= end) {
        console.log("hello deer creek collenction if statement");
        this.collectionId = 318528454809;
      }
    },
    willardBaySetCollectionID: function () {
      const date = new Date();
      //const date = new Date("2024-02-08");
      const start = new Date("2024-11-01");
      const end = new Date("2024-04-09");
      //nov 1 - april 9
      if (date >= start || date <= end) {
        console.log("willard bay off season");
        this.collectionId = 318531535001;
      }
    },
    weekendSetCollectionID: function () {
      //displays buy button
      const date = new Date();
      //   const date = new Date("2024/01/08");
      var day = date.getDay();
      //weekday sun=0, mon=1, tues=2, etc.
      if (day == 5 && this.parkName == "Willard Bay State Park") {
        this.collectionId = "318531469465";
      }
      //weekend FRI - Sun  for East Canyon & Yuba
      else if (
        this.parkName == "East Canyon State Park" ||
        this.parkName == "Yuba State Park" ||
        this.parkName == "Palisade State Park"
      ) {
        // console.log("weekend on Friday")
        if (day >= 1 && day < 5) {
          this.loadWeekday();
        } else {
          this.loadWeekend();
        }
      } else {
        // console.log("weekend on Saturday")
        //weekend SAT - Sun
        if (day >= 1 && day <= 5) {
          this.loadWeekday();
        } else {
          this.loadWeekend();
        }
      }
    },
    holidaySetCollectionID: function () {
      //   const date = new Date('2024-2-21');
      const date = new Date();
      //const date = new Date("2024-02-08");
      const month = date.getMonth() + 1;
      const day = date.getDate();
      console.log("month", month, day);
      // Friday, December 31, 2021*	New Year's Day
      if (month == 12 && day == 31) {
        this.loadWeekend();
      } else if (month == 1 && day == 1) {
        this.loadWeekend();
      }
      // Monday, January 17	Birthday of Martin Luther King, Jr.
      else if (month == 1 && day == 17) {
        this.loadWeekend();
      }
      // Monday, February 21**	Washington’s Birthday
      else if (month == 2 && day == 21) {
        this.loadWeekend();
      }
      // Monday, May 30	Memorial Day
      else if (month == 5 && day == 30) {
        this.loadWeekend();
      }
      // Monday, June 20*	Juneteenth National Independence Day
      else if (month == 6 && (day == 19 || day == 20)) {
        this.loadWeekend();
      }
      // Monday, July 4	Independence Day
      else if (month == 7 && day == 4) {
        this.loadWeekend();
      }
      // Monday, July 25th - Pioneer Day Observed
      else if (month == 7 && day == 25) {
        this.loadWeekend();
      }
      // Monday, September 5	Labor Day
      else if (month == 9 && day == 5) {
        this.loadWeekend();
      }
      // Monday, October 10	Columbus Day
      else if (month == 10 && day == 10) {
        this.loadWeekend();
      }
      // Friday, November 11	Veterans Day
      else if (month == 11 && day == 11) {
        this.loadWeekend();
      }
      // Thursday, November 24	Thanksgiving Day
      else if (month == 11 && day == 24) {
        this.loadWeekend();
      }
      // Monday, December 26*	Christmas Day
      else if (month == 12 && (day == 25 || day == 26)) {
        this.loadWeekend();
      }
    },
    onCheckboxClickedD() {
      if (this.physicalCheckbox == true) {
        this.physicalCheckbox = false;
      }
      this.digitalCheckbox = true;
    },
    onCheckboxClickedP() {
      if (this.digitalCheckbox == true) {
        this.digitalCheckbox = false;
      }
      this.physicalCheckbox = true;
    },
    goToPass() {
      // console.log("phy: ", this.physicalCheckbox);
      // console.log("dig: ", this.digitalCheckbox);
      console.log("pass: ", this.radios);

      if (this.radios == "physical") {
        this.$router.push("/parks/PANUAL/dayuse");
        window.location.reload();
      } else if (this.radios == "digital") {
        this.$router.push("/parks/DANUAL/dayuse");
        window.location.reload();
      } else {
        console.log("error with pass selection");
      }
    },
    loadWeekend: function () {
      if (this.parkName != "Bear Lake State Park") {
        for (let i = 0; i < this.collectionTableWeekend.length; i++) {
          if (this.parkName == this.collectionTableWeekend[i].name) {
            this.collectionId = this.collectionTableWeekend[i].collectionId;
          }
        }
      }
    },
    loadWeekday: function () {
      for (let i = 0; i < this.collectionTable.length; i++) {
        if (this.parkName == this.collectionTable[i].name) {
          this.collectionId = this.collectionTable[i].collectionId;
        }
      }
    },
    locationSelected: function (name) {
      for (let i = 0; i < this.parks.length; i++) {
        if (name == this.parks[i].abbr) {
          this.parkName = this.parks[i].name;
          this.currentParkMeta = this.parks[i].meta;
          this.currentPark = this.parks[i];
          if (this.parkName == "State Park Annual Pass") {
            this.breadcrumbs = [
              { text: "Home", disabled: false, href: "/" },
              // {
              //   text: "Annual Pass",
              //   disabled: false,
              //   href: "/parks/ANNUAL/dayuse",
              // },
              {
                text: "State Park Annual Pass",
                disabled: true,
                href: "/parks/" + this.$route.params.name,
              },
            ];
          } else if (this.parkName == "Digital Annual Pass") {
            this.breadcrumbs = [
              { text: "Home", disabled: false, href: "/" },
              // {
              //   text: "Annual Pass",
              //   disabled: false,
              //   href: "/parks/ANNUAL/dayuse",
              // },
              {
                text: "Digital Annual Pass",
                disabled: true,
                href: "/parks/" + this.$route.params.name,
              },
            ];
          } else if (this.parkName == "Annual Pass") {
            this.breadcrumbs = [
              { text: "Home", disabled: false, href: "/" },
              {
                text: "Annual Pass",
                disabled: true,
                href: "/parks/ANNUAL/dayuse",
              },
            ];
          } else {
            this.breadcrumbs = [
              { text: "Home", disabled: false, href: "/" },
              // { text: this.parkName, disabled: false, href: '/parks/' + this.$route.params.name},
              {
                text: "Day-Use Pass",
                disabled: true,
                href: "/parks/" + this.$route.params.name + "/dayuse",
              },
            ];
          }
        }
      }
      this.loadWeekday();
      this.weekendSetCollectionID();
      this.holidaySetCollectionID();
      if (this.parkName == "Echo State Park") {
        this.echoSetCollectionID();
      }
      if (this.parkName == "Bear Lake State Park") {
        this.bearLakeSetCollectionID();
      }
      if (this.parkName == "Deer Creek State Park") {
        this.deerCreekSetCollectionID();
      }
      if (this.parkName == "Goblin Valley State Park") {
        this.goblinValleySetCollectionID();
      }

      if (this.parkName == "Dead Horse Point State Park") {
        this.passNotOnline();
      }
      if (this.parkName == "Jordanelle State Park") {
        this.jordanelleSetCollectionID();
      }
      if (this.parkName == "Palisade State Park") {
        this.palisadeSetCollectionID();
      }
      // if (this.parkName == "Quail Creek State Park") {
      //   this.quailSetCollectionID();
      // }
      //   if (this.parkName == "Rockport State Park") {
      //     this.rockportSetCollectionID();
      //   }
      if (this.parkName == "Utah Lake State Park") {
        this.utahLakeSetCollectionID();
      }
      if (this.parkName == "Willard Bay State Park") {
        this.willardBaySetCollectionID();
      }

      var location = this.parkName;
      this.passList = [];
      this.ohv_details = false;
      this.shortParkName = location.replace(/\s+/g, "");
      for (let i = 0; i < this.parkPasses.length; i++) {
        if (this.parkPasses[i].name == location) {
          this.passList.push(this.parkPasses[i]);

          // ONLY FOR BEAR LAKE && JORDANELLE STATE PARK
          if (
            this.parkName == "Bear Lake State Park" ||
            this.parkName == "Jordanelle State Park"
          ) {
            // this.BearLakeNonPrime();
            if (this.parkOptions.indexOf(this.parkPasses[i].location) == -1) {
              this.parkOptions.push(this.parkPasses[i].location);
            }
          }
          // ONLY FOR JORDANELLE
          // if(this.parkName == 'Jordanelle State Park'){
          //   if(this.jordanelleOptions.indexOf(this.parkPasses[i].location) == -1){
          //       this.jordanelleOptions.push(this.parkPasses[i].location);
          //   }
          // }
        }
      }

      if (this.dateParks.includes(this.parkName)) {
        this.informationDateDisplay();
      }

      for (let i = 0; i < this.parks.length; i++) {
        if (this.parks[i].name == location) {
          this.learnLink = this.parks[i].site;
          this.parkDescription = this.parks[i].description;
        }
      }

      var lakes = [
        "Deer Creek State Park",
        "East Canyon State Park",
        "Echo State Park",
        "Fred Hayes State Park at Starvation",
        "Gunlock State Park",
        "Jordanelle State Park",
        "Quail Creek State Park",
        "Sand Hollow State Park",
        "Steinaker State Park",
        "Utah Lake State Park",
        "Willard Bay State Park",
        "Yuba State Park",
      ];
      this.detectPlates();
      if (lakes.includes(location)) {
        this.locations = false;
        // this.lakeWaiver = true;
        this.loadShopifyCollection();
      } else {
        this.loadShopifyCollection();
      }
      if (location == "Jordan River OHV State Park") {
        this.ohv_details = true;
      }
      if (this.parkName == "Bear Lake State Park") {
        this.currentParkOption = "Marina";
      } else if (this.parkName == "Jordanelle State Park") {
        this.currentParkOption = "Hailstone";
      }
    },
    detectPlates() {
      console.log("this.passList: ", this.passList);
      for (let i = 0; i < this.passList.length; i++) {
        if (this.passList[i].plate) {
          this.passesWithPlates.push(this.passList[i].passType);
        }
        // if (this.passList[i].name == "Deer Creek State Park") {
        //   //console.log("park list: ", this.passList[i]);
        //   //console.log("park plate: ", this.passList[i].plate);
        // }
      }
      console.log("passes with plates: ", this.passesWithPlates);
    },
    informationDateDisplay: function () {
      var date = new Date();
      //var date = new Date("2024/02/08");
      console.log("todays new data: ", date);
      var newList = [];
      for (let i = 0; i < this.passList.length; i++) {
        var pass = this.passList[i];
        if (pass.start_date != "" || pass.end_date != "") {
          console.log("true");
          if (pass.start_date.includes("/") && pass.end_date.includes("/")) {
            var sd = new Date(
              date.getFullYear(),
              pass.start_date.split("/")[0] - 1,
              pass.start_date.split("/")[1],
              0,
              0,
              0,
              0
            );
            var ed = new Date(
              date.getFullYear(),
              pass.end_date.split("/")[0] - 1,
              pass.end_date.split("/")[1],
              0,
              0,
              0,
              0
            );
            if (date >= sd && date <= ed) {
              console.log("pass in season: ", date);
              newList.push(pass);
              if (pass.season) {
                this.season = "(" + pass.season + ")";
              }
            } else {
              console.log("pass not in season: ", date);
            }
          }
        }
      }
      // console.log("pass list populating");
      this.passList = newList;
    },
    BearLakeNonPrime: function () {
      var date = new Date();
      // var date = new Date('2024/11/05');
      var newList = [];
      for (let i = 0; i < this.passList.length; i++) {
        var pass = this.passList[i];
        if (pass.start_date == "05/01") {
          newList.push(pass);
          if (pass.season) {
            this.season = "(" + pass.season + ")";
          }
        }
      }
      // console.log("pass list populating");
      this.passList = newList;
    },
    parkOptionSelected: function (option) {
      if (this.currentParkOption != option) {
        this.currentParkOption = option;
      } else {
        this.currentParkOption = "";
      }
    },
    waiverAccepted: function () {
      this.filterSearch = "";
      this.loadShopifyCollection();
    },
    initDivWatcher: function () {
      let shopifyDiv = document.getElementById("shopifyCollectionWrap");
      shopifyDiv.addEventListener("DOMNodeInserted", this.divEventListener);
    },
    divEventListener: function (ev) {
      let shopifyDiv = document.getElementById("shopifyCollectionWrap");
      var cDiv = shopifyDiv.getElementsByTagName("div")[1];
      var iDiv = cDiv.getElementsByTagName("iframe")[0];
      let iBody = iDiv.contentWindow.document.getElementsByTagName("body")[0];
      iBody.addEventListener("DOMNodeInserted", this.iFrameBodyEventListener);
      if (!iDiv.style.height) {
        if (this.myInterval == null) {
          this.myIntervalCount = 0;
          this.myInterval = setInterval(this.divHeightChecker, 200);
        }
      } else {
        console.log(iDiv.style.height);
      }
      shopifyDiv.removeEventListener("DOMNodeInserted", this.divEventListener);
    },
    divHeightChecker: function () {
      var iDiv = document
        .getElementById("shopifyCollectionWrap")
        .getElementsByTagName("div")[1]
        .getElementsByTagName("iframe")[0];
      //console.log(iDiv.style);
      if (!iDiv.style.height && this.myIntervalCount <= 50) {
        this.myIntervalCount++;
        //console.log(this.myIntervalCount);
      } else {
        this.stopLoader();
        if (iDiv.style.height) {
          document.getElementById("park-closed").style.display = "none";
          document.getElementsByClassName("collectionDiv")[0].style.display =
            "initial";
        } else {
          document.getElementById("park-closed").style.display = "initial";
          document.getElementsByClassName("collectionDiv")[0].style.display =
            "none";
        }
        clearInterval(this.myInterval);
      }
    },
    iFrameBodyEventListener: function (e) {
      this.stopLoader();
      if (this.myInterval != null) {
        clearInterval(this.myInterval);
      }
      document.getElementById("park-closed").style.display = "none";
      document.getElementsByClassName("collectionDiv")[0].style.display =
        "initial";
      //console.log("Added element to Body of iFrame",e);
    },
    loadShopifyCollection: function () {
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          this.ShopifyBuyInit(this.collectionId);
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },
    loadScript: function () {
      //create html script tag and run after it loads
      var scriptURL =
        "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = this.ShopifyBuyInit(this.collectionId);
    },
    stopLoader: function () {
      document.getElementById("loader").style.display = "none";
    },
    ShopifyBuyInit: function (collectionId) {
      console.log("this is the collectionID 2: ", collectionId);
      // this.initDivWatcher();
      var client = ShopifyBuy.buildClient({
        domain: "parkspassutah.myshopify.com",
        storefrontAccessToken: "a245460c3b59913598a8b8b1f049af40",
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent("collection", {
          id: collectionId,
          node: document.getElementById("collection-component-1675198796607"),
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                    width: "calc(25% - 20px)",
                  },
                  img: {
                    height: "calc(100% - 15px)",
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                  },
                  imgWrapper: {
                    "padding-top": "calc(75% + 15px)",
                    position: "relative",
                    height: "0",
                  },
                },
                title: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "normal",
                },
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
                price: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
                compareAt: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
                unitPrice: {
                  "font-family": "Roboto, sans-serif",
                  "font-weight": "bold",
                },
              },
              text: {
                button: "Add to cart",
              },
              googleFonts: ["Roboto"],
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                  },
                },
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
                title: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "bold",
                  "font-size": "26px",
                  color: "#4c4c4c",
                },
                price: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "18px",
                  color: "#4c4c4c",
                },
                compareAt: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
                unitPrice: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
              },
              text: {
                button: "Add to cart",
              },
            },
            option: {},
            cart: {
              styles: {
                button: {
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  "background-color": "#96a145",
                  ":focus": {
                    "background-color": "#87913e",
                  },
                  "border-radius": "40px",
                },
              },
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
              popup: false,
            },
            toggle: {
              styles: {
                toggle: {
                  "background-color": "#96a145",
                  ":hover": {
                    "background-color": "#87913e",
                  },
                  ":focus": {
                    "background-color": "#87913e",
                  },
                },
              },
            },
          },
        });
      });
    },
    checkBisonRoundup() {
      const now = new Date();
      const targetDate = new Date(now.getFullYear(), 8, 1, 8, 0, 0); // Month is 0-indexed, so 8 is September

      if (now > targetDate) {
        this.bisonRoundup = true;
      }
    },
    //end of methods
  },
  watch: {
    sdkReady: function (data) {
      if (data) {
        clearInterval(this.checkingInterval);
        this.locationSelected(this.$route.params.name);
      }
    },
  },
  computed: {
    filteredParkOptions() {
      return this.passList.filter(
        (pass) => pass.location === this.currentParkOption
      );
    },
  },
};
</script>

<style scoped>
#blue-mtn {
  background-color: "#A5C5DC";
}
.resonly {
  color: #494e53;
  font-family: "Roboto";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  margin: 0px 0px 20px 0px;
}
.storeButtons {
  flex-direction: row;
}
.annButton {
  color: #96a145;
  border: 2px solid #96a145;
  border-radius: 50px;
  padding: 7px 15px;
}
.checkbox {
  margin-top: 0px;
  height: 40px;
}
.orange-text {
  color: #ff6651;
}
.downlaod-button {
  background-color: #96a145;
  color: white;
  margin-right: 15px;
  padding: 15px;
  border-radius: 50px;
  display: flex;
}
.grey {
  background-color: #9f9f9f;
}
.downlaod-button > img {
  margin-right: 10px;
}
.gradBack {
  background: linear-gradient(
    270deg,
    #d60000 0%,
    rgba(255, 255, 255, 0.2) 60.08%,
    #0c500f 117.71%
  );
}
.v-application {
  min-height: 0px !important;
}
.v-application a {
  color: #96a145;
}
.page-wrap {
  padding: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.passGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
}
.delivery {
  font-family: "boldRoboto";
  font-size: 18px;
  margin: 8px 0px;
}
.delivery2 > p {
  font-family: "Roboto";
  font-size: 18px;
  margin: 8px 0px;
}

#apology {
  font-size: 48px;
  font-weight: 700;
  font-family: "boldRoboto";
  padding-top: 14px;
}
#apology2 {
  font-size: 32px;
  font-family: "boldRoboto";
  padding-top: 10px;
}
#apology3 {
  font-size: 20;
  padding-top: 14px;
}
.rentals {
  background-color: #96a14517;
  width: 100%;
  padding: 32px;
}
.rentalContent {
  padding-left: 32px;
  max-width: 1600px;
  margin: 0 auto;
}
.rentalContainer {
  display: flex;
  margin-top: 16px;
}
.col1 {
  object-fit: cover;
  border-radius: 5px;
  height: 100%;
  padding-top: 5px;
}
.col2 {
  padding-left: 16px;
}
#signUpButton {
  letter-spacing: 0.3px;
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 40px;
  border-radius: 40px;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 1000;
}
#signUpButton2 {
  letter-spacing: 0.3px;
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 22px;
  border-radius: 40px;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 1000;
}
.eventInfo {
  display: flex;
}
#banner {
  width: 80%;
}
#bannerImage2 {
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}
#bannerImage {
  display: inline-block;
  vertical-align: middle;
  margin-right: 24px;
}
.buttonContainer {
  /* float:right; */
  display: inline-block;
  margin: 12px 16px;
  cursor: pointer;
  margin-left: auto;
}
.buttonContainer2 {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: -32px;
}
#eta {
  font-size: 18px;
  margin: 8px 0px;
  display: inline;
  font-weight: 700;
}
.Attention {
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  font-family: "boldRoboto";
}
.eventText {
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  font-family: "boldRoboto";
  /* white-space: nowrap; */
}
.dayBanner {
  background-color: #409fae10;
  border: 2px solid #409fae;
  padding: 2px;
  display: flex;
  align-items: center;
  margin: 0px;
  margin-bottom: 16px;
  width: calc(100%);
}

.eventLink {
  background-color: #e3e7ba;
  padding: 10px;
  display: flex;
  align-items: center;
  padding: 8px 32px;
  width: calc(100%);
}

.beige {
  background-color: #f0e7d7;
}

.blue {
  background-color: #d1e1ed;
}

.eventIcon {
  display: flex;
  width: 100%;
}
.eventLinkBlue {
  background-color: #d1e1ed;
  padding: 10px;
  display: flex;
  align-items: center;
  padding: 8px 32px;
  width: calc(100%);
}
#green-mtn {
  background-image: url("../assets/images/green-mtn.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: cover;
}

/* PRODUCT CSS DONE IN JS */

@media only screen and (max-width: 1400px) {
  .passGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 800px) {
  .passGrid {
    grid-template-columns: auto;
  }
}
.shopify-buy__option-select__select {
  height: 42px;
  width: 80px;
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
}
a {
  color: #96a145;
}
[data-element="option.select"] {
  height: 62px;
  width: 80px;
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
}
input[type="text"][name="properties[License plate]"] {
  border: 5px solid black !important;
}
.parkTitleWrap {
  text-align: left;
  margin-bottom: 50px;
}
h1 {
  font-family: "boldRoboto";
  font-size: 36px;
  margin: 0px 0px;
  margin-top: 32px;
}
h2 {
  margin: 0px;
}
.divider {
  margin: 32px 0px;
}

.row {
  display: flex;
  padding-bottom: 42px;
}
.column {
  flex: 50%;
}
.bGreen {
  color: #96a145;
}
.column div {
  margin-left: 12px;
}
.column div b {
  font-size: 18px;
  font-family: "boldRoboto";
  margin-right: 14px;
  height: 22px;
  vertical-align: top;
}
.column div p {
  margin: 8px;
  margin-top: 12px;
  font-size: 16px;
  display: inline-block;
  width: 80%;
}
.Q {
  margin-top: 24px;
}
.greenLink {
  color: #96a145;
  text-decoration: underline #96a145 !important;
  font-size: 16px;
  font-family: "boldRoboto";
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.shopifyCollectionWrap {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.shopifyBox,
.shopifyBox2 {
  background-color: white;
  display: inline-block;
  width: auto;
  border: 1px solid #c4c4c4;
  padding: 16px;
  position: relative;
  margin-left: 0px;
}
.shopifyBox2 {
  margin-top: 16px;
  width: 100%;
}
.waiverLink {
  font-size: 16px;
  color: #96a145;
}
.parkLink {
  font-size: 16px;
  text-decoration: underline;
  color: #96a145;
  font-family: "mediumRoboto";
  cursor: pointer;
  display: block;
}
.discGolfBanner {
  height: 100px;
  background-color: #96a145;
}
.passContainer {
  display: flex;
  margin: 12px 0px;
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 32px;
  white-space: break-spaces;
}
.annual_head {
  font-size: 18px;
  font-family: "boldRoboto";
  margin: 18px 0px 8px;
}
li {
  font-size: 16px;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 8px;
}

h4 {
  margin-top: 4px;
}
.GSLheader {
  font-size: 11px;
  text-transform: uppercase;
  font-family: boldRoboto;
  /* padding-bottom: 6px; */
  padding-top: 12px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.passTitle {
  font-size: 18px;
  font-family: "boldRoboto";
  margin: 0px;
}
.passType {
  font-size: 16px;
  font-family: "boldRoboto";
  color: #424242;
  margin-top: 0px;
}
.searchHeader {
  color: white;
  font-size: 18px;
  display: inline;
  padding-top: 16px;
}
.search-page-wrap {
  padding: 8px;
}
.passTextRed {
  color: #fd251e;
  position: relative;
  bottom: 0px;
  padding-top: 16px;
  left: 0px;
  font-family: "boldRoboto";
  font-size: 16px;
  margin: 0px;
  margin-right: 16px;
}
.blackBack {
  background-color: #1e252b;
  height: 100vh;
}
#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}
#overlay-card {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: black;
  background: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 16px;
  max-width: 500px;
}
#overlay-card h3 {
  font-family: "boldRoboto";
  font-size: 18px;
  margin: 8px 0px;
}
#overlay-card p {
  margin: 8px 0px;
}
#overlay-card div {
  color: #96a145;
  font-size: 16px;
  font-family: "mediumRoboto";
  display: inline;
  padding: 12px;
  border-radius: 40px;
  float: right;
}
#overlay-card div:hover {
  background: #96a14560;
}

.shopify-box2 p {
  margin: 8px 0px;
}
.shopify-box2 a {
  color: #96a145;
  font-size: 20px;
  font-family: "mediumRoboto";
  display: inline;
  padding: 12px;
  border-radius: 40px;
  float: right;
}
.shopify-box2 a:hover {
  background: #96a14560;
}
.flatTop {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.flatBottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.parkOption {
  padding: 12px;
  margin: 12px 0px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
}
.parkOptionTitle {
  display: inline-block;
  font-size: 16px;
  font-family: "boldRoboto";
}
.arrow {
  float: right;
  height: 6px;
  margin-top: 10px;
  transform: rotate(0deg);
}
.flipArrow {
  transform: rotate(180deg);
}
.passText {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
}
h4 {
  margin-top: 4px;
}
.flexWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.passTitle {
  font-size: 18px;
  font-family: "boldRoboto";
}
.passType {
  font-size: 16px;
  font-family: "boldRoboto";
  color: #424242;
  margin-top: 0px;
  margin: 0px;
}
.searchHeader {
  color: white;
  font-size: 18px;
  display: inline;
  padding-top: 16px;
}
.search-page-wrap {
  padding: 8px;
}
.passTextRed {
  color: #fd251e;
  position: relative;
  bottom: 0px;
  padding-top: 16px;
  left: 0px;
  font-family: "boldRoboto";
  font-size: 16px;
  margin-right: 16px;
}
.blackBack {
  background-color: #1e252b;
  height: 100vh;
}
.v-breadcrumbs ::v-deep(a) {
  color: #96a145;
}
@media only screen and (max-width: 1000px) {
  h1 {
    font-family: "boldRoboto";
    font-size: 24px;
    margin: 8px 0px;
    margin-top: 16px;
  }
  h2 {
    margin: 8px 0px;
    font-size: 14px;
  }
  .dayBanner {
    margin-bottom: 8px;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
    width: auto;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
    padding: 0px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shofpifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
  .passTitle {
    font-size: 18px;
  }
  .page-wrap .eventLink .eventLinkBlue {
    padding: 16px;
  }
  .buttonContainer2 {
    margin-top: 16px;
  }
  .rentals {
    padding: 16px;
  }
}
/* spinner/processing state, errors */
#loader,
#loader:before,
#loader:after {
  border-radius: 50%;
}
#loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #96a145; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: loading 2s linear infinite;
  margin: 0 auto;
  margin-top: 60px;
}

@media only screen and (max-width: 400px) {
  .centered-home {
    margin-top: 24px;
  }
}
@media (max-width: 425px) {
  .storeButtons {
    flex-direction: column;
  }
  .downlaod-button {
    margin-top: 20px;
    margin-right: 0px;
    justify-content: center;
  }
  .resonly {
    font-size: 20px;
  }
}
/* mobile size */
@media only screen and (max-width: 600px) {
  .eventInfo {
    margin: auto;
    display: flex;
  }
  #overlay-card {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
  }
  form {
    width: 80vw;
  }
  .eventLink {
    display: inline-grid;
  }

  .eventLinkBlue {
    display: inline-grid;
  }
  .eventIcon {
    display: -webkit-box;
  }
  #bannerImage {
    padding-top: 8px;
  }
  .buttonContainer {
    margin: auto;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  #apology {
    font-size: 32px;
    font-family: "boldRoboto";
    padding-top: 8px;
  }
  #apology2 {
    font-size: 20px;
    font-family: "boldRoboto";
    padding-top: 8px;
  }
  #apology3 {
    font-size: 16px;
    padding-top: 8px;
  }
}
@media only screen and (max-width: 1660px) {
  .rentalContent {
    padding-left: 0px;
  }
}
@media only screen and (max-height: 1200px) {
  .locationsWrap {
    max-height: 30vh;
  }
}
@media only screen and (max-height: 900px) {
  .locationsWrap {
    max-height: 20vh;
  }
}
@media only screen and (max-width: 320px) {
  .lightsicon {
    margin-right: 0px !important;
  }
  .park-unavailable {
    width: 100%;
  }
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
