<template>
  <div>
    <v-app>
      <!-- Put this anywhere in your <template>, often right below <v-app> or at the end -->
      <v-dialog v-model="dialogLegend" max-width="600px" scrollable persistent>
        <v-card>
          <v-card-title class="legend-dialog-title">
            Icon Legend
            <v-spacer></v-spacer>
            <!-- Close button -->
            <v-btn icon @click="dialogLegend = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <div class="icon-legend-grid">
              <!-- 1 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/restroom.svg"
                  alt="Restrooms"
                />
                <span>Restrooms</span>
              </div>
              <!-- 2 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/camping.svg"
                  alt="Camping"
                />
                <span>Camping</span>
              </div>
              <!-- 3 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/dump-station.svg"
                  alt="Dump Station"
                />
                <span>Dump Station</span>
              </div>
              <!-- 4 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/dogs.svg"
                  alt="Dogs Allowed"
                />
                <span>Dogs Allowed</span>
              </div>
              <!-- 5 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/hiking.svg"
                  alt="Hiking"
                />
                <span>Hiking</span>
              </div>
              <!-- 6 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/swimming.svg"
                  alt="Swimming"
                />
                <span>Swimming</span>
              </div>
              <!-- 7 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/boat-launch.svg"
                  alt="Boating"
                />
                <span>Boating</span>
              </div>
              <!-- 8 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/fishing.svg"
                  alt="Fishing"
                />
                <span>Fishing</span>
              </div>
              <!-- 9 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/kayaking.svg"
                  alt="Kayaking"
                />
                <span>Kayaking</span>
              </div>
              <!-- 10 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/wifi.svg"
                  alt="Free WiFi"
                />
                <span>Free WiFi</span>
              </div>
              <!-- 11 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/wheelchair.svg"
                  alt="Wheelchair Accessible"
                />
                <span>Wheelchair Accessible</span>
              </div>
              <!-- 12 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/picnic.svg"
                  alt="Picnic Area"
                />
                <span>Picnic Area</span>
              </div>
              <!-- 13 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/campfire.svg"
                  alt="Firepits"
                />
                <span>Firepits</span>
              </div>
              <!-- 14 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/museum.svg"
                  alt="Museum"
                />
                <span>Museum</span>
              </div>
              <!-- 15 -->
              <div class="icon-legend-item">
                <img
                  src="../assets/newHomeIcons/IconLegend/golfing.svg"
                  alt="Firepits"
                />
                <span>Golfing</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <Header v-if="!mobileSearch" />
      <div v-if="mobileSearch == true" class="blackBack">
        <div class="search-page-wrap">
          <p class="searchHeader">Search a Utah State Park</p>
          <img
            class="white-x"
            src="../assets/icons/whitex.png"
            alt="white X"
            @click="mobileSearch = false"
          />
          <div class="searchBox" style="width: 100%; box-sizing: border-box">
            <v-autocomplete
              id="autocomplete-mobile"
              @load="mobileSearchLoaded()"
              @change="locationSelected()"
              @focus="searchFocus()"
              @blur="searchBlur()"
              v-model="park"
              auto-select-first
              background-color="#96A145"
              dark
              color="#FFFFFF"
              :menu-props="{ bottom: true, offsetY: true, maxHeight: 220 }"
              item-text="name"
              placeholder="Find your destination..."
              :items="parks"
              filled
              :rounded="!searchFocused"
              class="select-park-mobile"
            >
              <template v-slot:append>
                <v-icon color="white" style="margin-right: 8px"
                  >mdi-magnify</v-icon
                >
              </template>
            </v-autocomplete>
          </div>
        </div>
      </div>
      <div
        v-bind:style="{ backgroundImage: 'url(' + homeHeroImg + ')' }"
        v-else
      >
        <div id="homeImgContainer">
          <div class="centered-home">
            <!-- <h2 class="home1">PLAN YOUR TRIP AHEAD!</h2> -->
            <h1 class="home2">Digital Utah State Park Passes</h1>
            <h3 class="home3">Buy day-use passes online.</h3>
            <v-autocomplete
              id="autocomplete"
              @change="locationSelected()"
              @focus="searchFocus()"
              @blur="searchBlur()"
              v-model="park"
              auto-select-first
              background-color="#96A145"
              dark
              color="#FFFFFF"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="name"
              placeholder="Find your destination..."
              :items="parks"
              filled
              :rounded="!searchFocused"
              class="select-park"
            >
              <template v-slot:append>
                <v-icon color="white" style="margin-right: 8px"
                  >mdi-magnify</v-icon
                >
              </template>
            </v-autocomplete>
          </div>
        </div>

        <div id="page-padding">
          <div id="filter-menu">
            <div
              v-for="(filter, index) in filterItems"
              :key="index"
              class="filter-item"
              :class="{ 'active-filter': selectedFilter === filter.name }"
              @click="selectedFilter = filter.name"
            >
              <img :src="filter.icon" alt="" />
              <p class="filter-text">{{ filter.name }}</p>
            </div>
          </div>

          <div id="above-passes">
            <p class="subheading">Available Day-Use Passes</p>
            <div id="above-passes2">
              <p id="legend" @click="dialogLegend = true">Icon Legend</p>
              <p id="location-num">{{ filteredParks.length }} Locations</p>
            </div>
          </div>

          <div id="bottom-section">
            <div id="passes">
              <v-card
                class="park-card"
                v-for="park in filteredParks"
                :key="park.abbr"
              >
                <img
                  class="park-images"
                  :src="require(`@/assets/HomeImages/${park.image}`)"
                  alt=""
                />
                <div class="card-text-cont">
                  <div class="location">
                    <img src="../assets/newHomeIcons/location.svg" alt="" />
                    <p class="location-text">{{ park.location }}</p>
                  </div>
                  <p class="park-name">{{ park.name }}</p>
                  <p class="pass-type">{{ park.type }}</p>
                  <div class="icons">
                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.restrooms"
                          src="../assets/newHomeIcons/IconLegend/restroom.svg"
                          alt="Restrooms"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Restrooms</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.camping"
                          src="../assets/newHomeIcons/IconLegend/camping.svg"
                          alt="Camping"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Camping</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.dumpStation"
                          src="../assets/newHomeIcons/IconLegend/dump-station.svg"
                          alt="Dump Station"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Dump Station</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.dogsAllowed"
                          src="../assets/newHomeIcons/IconLegend/dogs.svg"
                          alt="Dogs Allowed"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Dogs Allowed</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.hiking"
                          src="../assets/newHomeIcons/IconLegend/hiking.svg"
                          alt="Hiking"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Hiking</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.swimming"
                          src="../assets/newHomeIcons/IconLegend/swimming.svg"
                          alt="Swimming"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Swimming</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.boating"
                          src="../assets/newHomeIcons/IconLegend/boat-launch.svg"
                          alt="Boating"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Boating</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.fishing"
                          src="../assets/newHomeIcons/IconLegend/fishing.svg"
                          alt="Fishing"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Fishing</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.kayaking"
                          src="../assets/newHomeIcons/IconLegend/kayaking.svg"
                          alt="Kayaking"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Kayaking</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.wifi"
                          src="../assets/newHomeIcons/IconLegend/wifi.svg"
                          alt="WiFi"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>WiFi</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.wheelChair"
                          src="../assets/newHomeIcons/IconLegend/wheelchair.svg"
                          alt="Wheelchair Accessible"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Wheelchair Accessible</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.picnic"
                          src="../assets/newHomeIcons/IconLegend/picnic.svg"
                          alt="Picnic Area"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Picnic Area</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.firepits"
                          src="../assets/newHomeIcons/IconLegend/campfire.svg"
                          alt="Firepits"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Firepits</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      dark
                      color="grey darken-3"
                      content-class="my-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          class="amen-icon"
                          v-if="park.museum"
                          src="../assets/newHomeIcons/IconLegend/museum.svg"
                          alt="Museum"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Museum</span>
                    </v-tooltip>
                  </div>

                  <button class="card-button" @click="viewDayusePage(park)">
                    View Details
                  </button>
                  <!-- <div class="button-cont">
                    <button class="card-button">View Details</button>
                  </div> -->
                </div>
              </v-card>
            </div>

            <div id="mapContainer">
              <iframe
                :src="mapUrl"
                title="Utah Parks Map"
                class="mapIframe"
              ></iframe>
            </div>
          </div>
        </div>

        <!-- <div id="mapContainer">
          <iframe
            src="https://utahparksmap.web.app"
            title="Utah Parks Map"
            class="mapIframe"
          ></iframe>
        </div> -->

        <Footer></Footer>
      </div>
    </v-app>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

import $ from "jquery";

import { parksJson } from "../assets/ParkFiles/newparks";

export default {
  name: "Home",
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      iframeTimestamp: Date.now(),
      backgroundImage: "",
      mobile: false,
      locations: "",
      lakeWaiver: false,
      filterSearch: "",
      parks: parksJson,
      // parks_menu: parksJson,
      mobileSearch: false,
      park: "",
      searchFocused: false,
      // Which filter is active?
      selectedFilter: "All Parks",

      // Example list of filter items
      filterItems: [
        { name: "All Parks", icon: require("../assets/newHomeIcons/utah.svg") },
        {
          name: "Boating",
          icon: require("../assets/newHomeIcons/boating.svg"),
        },
        {
          name: "Camping",
          icon: require("../assets/newHomeIcons/camping.svg"),
        },
        {
          name: "Fishing",
          icon: require("../assets/newHomeIcons/fishing.svg"),
        },
        { name: "Hiking", icon: require("../assets/newHomeIcons/hiking.svg") },
        {
          name: "Mountain Biking",
          icon: require("../assets/newHomeIcons/biking.svg"),
        },
        {
          name: "Museums",
          icon: require("../assets/newHomeIcons/museums.svg"),
        },
        {
          name: "Off-Highway",
          icon: require("../assets/newHomeIcons/off-highway.svg"),
        },
        {
          name: "Golfing",
          icon: require("../assets/newHomeIcons/golf.svg"),
        },
      ],
      dialogLegend: false,
    };
  },

  metaInfo() {
    return {
      title: "Parkspass",
      titleTemplate: null,
      meta: [{ name: "description", content: this.currentParkMeta }],
    };
  },
  mounted() {
    window.addEventListener("pageshow", function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    });
  },
  created() {
    console.log(this.parks);
    var newParks = [];
    for (let i in this.parks) {
      if ((this.parks[i].abbr != "DANUAL") & (this.parks[i].abbr != "ANNUAL")) {
        newParks.push(this.parks[i]);
      }
    }
    this.parks = newParks;
    // PARKS PASS APP
    if ($(window).width() <= 720) {
      this.mobile = true;
    }
    if (!this.parkFromArg()) {
      window.addEventListener("popstate", this.statePopped);
    }

    //this.homeHeroImg = "../assets/images/snowcanyon-large.jpg";
    this.homeHeroImg = "../assets/images/snowcanyon-small.jpg";

    // this.loadHomeImg();
    // this.parks_menu.shift()
  },
  updated() {
    if (this.mobileSearch) {
      document.getElementById("autocomplete-mobile").click();
      this.searchFocused = true;
    }
  },
  methods: {
    viewDayusePage(park) {
      this.$router.push(`/parks/${park.abbr}/dayuse`).then(() => {
        window.scrollTo(0, 0);
      });
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        console.log("is mobile");
        return true;
      } else {
        console.log("desktop");
        return false;
      }
    },
    searchFocus() {
      this.searchFocused = true;
      if (this.mobile) {
        this.mobileSearch = true;
      }
    },
    searchBlur() {
      this.searchFocused = false;
    },
    loadHomeImg: function () {
      var screenWidth = window.innerWidth;
      if (screenWidth < 500) {
        this.homeHeroImg = "../assets/images/snowcanyon-small.jpg";
      } else if (screenWidth < 800) {
        this.homeHeroImg = "../assets/images/snowcanyon-medium.jpg";
      } else if (screenWidth < 1400) {
        this.homeHeroImg = "../assets/images/snowcanyon-large.jpg";
      }
    },
    //get park from arguments
    parkFromArg: function () {
      // This function is solely used for the QR codes that are advertised in the parks to rederict them to the right place.
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has("p")) {
        const parkAbbr = urlParams.get("p").toUpperCase();
        const reg = /^[A-Z]{6}$/;
        if (!reg.test(parkAbbr)) {
          return false;
        }
        var parkName = null;
        var abbr = null;
        for (var i = 0; i < this.parks.length; i++) {
          if (this.parks[i].abbr == parkAbbr) {
            parkName = this.parks[i].name;
            abbr = this.parks[i].abbr;
            this.routerPush(abbr);
            break;
          }
        }
        if (parkName != null && abbr != null) {
          return true;
        }
        /*if (parkName !== null) {
            this.park = parkName;
            var abbr;
            for (var j = 0; j < this.parks.length; j++) {
              if (this.parks[j].name == this.park) {
                abbr = this.parks[j].abbr;
                this.routerPush(abbr);
                break;
              }
            }
            return true;
          } else {
            return false;
          }*/
      }
      return false;
    },
    locationSelected: function () {
      var abbr;
      for (var i = 0; i < this.parks.length; i++) {
        if (this.parks[i].name == this.park) {
          abbr = this.parks[i].abbr;
          this.routerPush(abbr);
          break;
        }
      }
    },
    routerPush(abbr) {
      if (abbr == "UTFLIG") {
        this.$router.push(`/parks/${abbr}/camping`);
      } else if (abbr == "UTRAPT") {
        this.$router.push(`/parks/${abbr}/camping`);
      }
      // else if (abbr == "UTJOHV") {
      //   this.$router.push("/parks/UTJOHV/jordan");
      // }
      else {
        this.$router.push(`/parks/${abbr}/dayuse`);
      }
    },
  },
  computed: {
    mapUrl() {
      return `https://utahparksmap.web.app?reload=${this.iframeTimestamp}`;
    },
    filteredParks() {
      // If "All Parks" is selected, return everything
      if (this.selectedFilter === "All Parks") {
        return this.parks;
      }

      // Otherwise, filter based on the boolean property that matches the selected filter.
      switch (this.selectedFilter) {
        case "Boating":
          return this.parks.filter((park) => park.boating);
        case "Camping":
          return this.parks.filter((park) => park.camping);
        case "Fishing":
          return this.parks.filter((park) => park.fishing);
        case "Hiking":
          return this.parks.filter((park) => park.hiking);
        case "Mountain Biking":
          // If your data uses `park.biking` or `park.mountainBiking`—adjust accordingly
          return this.parks.filter((park) => park.biking);
        case "Museums":
          return this.parks.filter((park) => park.museum);
        case "Off-Highway":
          // If your data uses `park.offHighway` or `park.ohv`—adjust accordingly
          return this.parks.filter((park) => park.offHighway);
        case "Golfing":
          return this.parks.filter((park) => park.golf);
        default:
          return this.parks;
      }
    },
  },
};
</script>

<style>
.v-list-item__title {
  color: #96a145 !important;
}
.v-list-item:hover {
  background-color: #96a14520;
}
.v-list-item--highlighted {
  background-color: #96a14540;
}
.v-list-item--link:before {
  background-color: none;
}
/* Global CSS: Ensure this style block is NOT scoped */
.v-tooltip__content.my-tooltip,
.v-tooltip__content.my-tooltip * {
  color: #fff !important;
  background-color: #424242 !important;
}
.v-text-field.v-text-field--enclosed {
  padding: unset !important;
  margin: 0px 20px 0px 20px !important;
}
</style>
<style scoped>
/* filter stuff */
.filter-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  width: 115px;
}

/* Default text color for the filter text (if you want) */
.filter-text {
  color: #414141;
  margin-bottom: 8px;
  text-align: center;
}

/* When a filter is “active,” make text black, add underline */
.active-filter .filter-text {
  color: black;
  /* A quick underline can be done with border-bottom: */
  border-bottom: 2px solid black;
}

/* For turning an SVG or PNG icon black, you can use a CSS filter. 
   Adjust to your liking or omit if you have your own black icons. */
.active-filter img {
  filter: brightness(0) invert(0);
}
.icon-legend-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.icon-legend-item {
  display: flex;
  align-items: center;
}

.icon-legend-item img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.legend-dialog-title {
  color: #2f2f2f;
  font-family: Roboto, sans-serif;
  font-size: 36px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

/* Container for icon+label pairs */
.icon-legend-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

/* Each icon+label row */
.icon-legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* optional */
}

/* Icon label styling */
.icon-legend-item span {
  color: #2f2f2f;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* or a numeric value if desired */
  margin-left: 8px; /* space between icon & label */
}

#above-passes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 66%;
  margin-left: 25px;
  margin-top: 25px;
}
#above-passes2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 305px;
}
.subheading {
  color: #414141;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}
#legend {
  color: #477ba1;
  text-align: right;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
  letter-spacing: 0.4px;
}
#location-num {
  color: #414141;
  text-align: right;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.icons {
  height: 78px;
}
.button-cont {
  display: flex;
}
.card-button {
  border-radius: 8px;
  background: #727a35;
  color: #fff;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.4px;
  width: calc(100% - 30px);
  padding: 15px;
  position: absolute;
  bottom: 20px;
  padding: 15px;
}
.amen-icon {
  margin: 4px;
}
.park-name {
  color: #414141;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.4px;
  margin-bottom: 0px;
  margin-top: 15px;
}
.pass-type {
  color: #414141;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 0px;
}
.location {
  display: flex;
  align-items: center;
}
.location-text {
  color: #414141;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.4px;
  margin-bottom: 0px;
  margin-left: 10px;
}
.card-text-cont {
  padding: 10px 15px;
}
.park-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.park-card {
  width: 30%;
  margin-bottom: 25px;
  min-height: 440px;
  max-width: 260px;
}
#bottom-section {
  margin-top: 1rem;
  display: flex;
}
#passes {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
}
#mapContainer {
  width: 30%;
  height: 1000px;
}
#page-padding {
  margin: 3rem 5rem;
}
#filter-menu {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  margin-top: 20px;
  align-items: center;
}
.filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  width: 115px;
}
.filter-item > img {
  margin-bottom: 8px;
}
.select-park,
.select-park-mobile {
  z-index: 1;
  color: white;
  padding: 0px 8px !important;
  margin-top: 32px !important;
}
.select-park-mobile {
  margin-top: 6px !important;
}
.v-select ::v-deep(input) {
  font-size: 1.2em !important;
}
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
html,
body {
  overflow-x: hidden !important;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}
a:link {
  text-decoration: none;
}
.previewImg {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 625px) {
  .previewImg {
    width: 200px;
  }
}
#previewImgContainer {
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* Home Page */
#homeImgContainer {
  /* Full height */
  /* height: 84vh; */
  background-image: url("../assets/HomeImages/homepage.png");
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}
.homepageContainer {
  max-width: 1200px;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 56px;
}
.home1 {
  color: #ffffff;
  font-size: 24px;
  font-family: "boldRoboto";
  margin: 0px;
}
.home2 {
  color: #ffffff;
  font-size: 48px;
  font-family: "boldRoboto";
  margin: 0px;
  line-height: 72px;
  padding-top: 24px;
}
.home3 {
  color: #ffffff;
  font-size: 24px;
  font-family: "Roboto";
  margin: 0px;
  margin-bottom: 30px;
}
.navbar {
  position: absolute;
  top: 68px;
  max-width: 1200px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 56px;
}
.logo,
.logoSmall {
  width: auto;
  height: 62px;
}
.logoSmall {
  vertical-align: middle;
  width: auto;
  margin-top: 14px;
  cursor: pointer;
  margin-left: 20px;
}
.centered-home {
  position: relative;
  margin: 0 auto;
  text-align: center;
  max-width: 700px;
  font-family: "boldRoboto";
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 80px;
}
.dnrFooter {
  height: 120px;
  float: right;
  margin-top: -80px;
}
.dnrFooter {
  height: 120px;
  float: right;
  margin-top: -80px;
}
.destinationsBtn {
  display: inline;
  font-size: 24px;
  color: white;
  cursor: pointer;
  font-weight: bold;
}
.helpBtn {
  float: right;
  height: 24px;
  width: auto;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 16px;
}
.searchContainer {
  position: relative;
  top: 32px;
  bottom: 0;
  width: 600px;
  margin: 0 auto;
  display: block;
}
.searchWrapper {
  position: relative;
  bottom: 0;
  height: 170px;
}
.singleBar:focus {
  outline: 0 none;
}
.search-page-wrap {
  padding: 8px;
  box-sizing: border-box;
}
.locationsWrap {
  background-color: white;
  width: 420px;
  height: 34vh;
  min-height: 200px;
  -webkit-box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.7);
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.7);
  overflow-y: scroll;
  z-index: 50000;
  position: relative;
  padding-top: 0px;
  text-align: left;
  margin: 0 auto;
  border-radius: 20px;
}
.locationTextWrap {
  width: 100%;
  height: auto;
  padding: 0px;
}
.locationText {
  font-size: 24px;
  line-height: 32px;
  margin: -16px 0px;
  padding: 8px 4px;
  color: #96a145;
  list-style: none;
  cursor: pointer;
  padding-left: 26px;
}
.locationText:hover {
  background: #eaecda;
}
.search {
  background: #96a145;
  color: white;
  font-size: 24px;
  background-image: url("../assets/icons/magnifyingGlass.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 26px;
  box-shadow: none;
  -webkit-appearance: none;
  background-origin: content-box;
  border-radius: 50px;
  height: 72px;
  width: 356px;
  padding: 0px 32px;
  border: none;
  cursor: pointer;
}
.blackBack {
  background-color: #1e252b;
  height: 100vh;
}
.searchHeader {
  margin-top: 0px;
  color: white;
  font-size: 18px;
  margin-left: 16px;
  display: inline;
  padding-top: 16px;
}
.white-x {
  float: right;
  vertical-align: middle;
  height: 24px;
  margin-right: 16px;
  margin-top: -2px;
}
.mobileSearch {
  background: #96a145;
  color: white;
  font-size: 20px;
  background-image: url("../assets/icons/magnifyingGlass.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  box-shadow: none;
  -webkit-appearance: none;
  background-origin: content-box;
  border-radius: 20px;
  height: 56px;
  width: 100%;
  padding: 0px 32px;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  padding-left: 26px !important;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.footer {
  width: 100%;
  height: auto;
  background: #1e252b;
  position: relative;
  vertical-align: middle;
  text-align: center;
  bottom: 0;
  padding-bottom: 10px;
}
.footerText {
  color: white;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0px;
}
.footerText a {
  font-family: "boldRoboto";
  font-size: 16px;
  cursor: pointer;
}
.social-button {
  background: #96a145;
  color: white;
  font-size: 18px;
  box-shadow: none;
  -webkit-appearance: none;
  background-origin: content-box;
  border-radius: 50px;
  padding: 4px 24px;
  border: none;
  cursor: pointer;
  width: fit-content;
  margin: auto;
}
.homeMessage,
.homeMessage2 {
  width: 564px;
  height: 72px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  position: relative;
  padding: 0px 16px;
  padding-top: 2px;
  display: table-cell;
  vertical-align: middle;
}
.homeMessage2 {
  position: absolute;
  bottom: 30px;
  color: white;
  display: table;
}
.searchBtn {
  position: absolute;
  top: 0;
  height: 48px;
  width: 48px;
  background-color: #96a145;
  display: inline-block;
  cursor: pointer;
}
.searchImg {
  margin-top: 16px;
  margin-left: 16px;
  height: 15px;
  width: 15px;
}
.homeMessage p {
  color: white;
  font-size: 14px;
  margin: 0;
  position: absolute; /* 2 */
  top: 50%; /* 3 */
  transform: translate(0, -50%);
}
.blackRect {
  width: 100%;
  height: 112px;
  background-color: #000000;
  display: block;
  position: relative;
  margin-top: -4px;
}
.blackRectLogo {
  color: white;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  position: absolute; /* 2 */
  top: 50%; /* 3 */
  left: 0%;
  right: 0%;
  margin: auto;
  transform: translate(0, -50%);
  height: 56px;
}
.blackRectLogo b {
  font-size: 18px;
  font-family: "boldRoboto";
}
.center {
  text-align: center;
}
#mapImg {
  width: 54px;
  height: 54px;
  margin: 54px;
}
h1 {
  font-family: "boldRoboto";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  margin: 0px;
  color: rgba(0, 0, 0, 0.86);
}
h2 {
  font-family: "boldRoboto";
  font-size: 36px;
}
.parkTitle {
  font-size: 48px;
  font-family: "boldRoboto";
  padding: 32px 0px;
}
.flatTop {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.flatBottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.parkOption {
  width: 100%;
  padding: 12px;
  margin: 12px 0px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
}
.parkOptionTitle {
  display: inline-block;
  font-size: 16px;
  font-family: "boldRoboto";
}
.arrow {
  float: right;
  height: 6px;
  margin-top: 8px;
  transform: rotate(180deg);
}
.flipArrow {
  transform: rotate(0deg);
}
.mapBanner {
  margin: 0 auto;
  margin-top: 32px;
}
/* #mapContainer {
  height: 80vh;
  width: 100%;
  text-align: center;
  position: relative;
  margin: 0 auto;
  margin-top: 32px;
} */
.mapIframe {
  width: 100%;
  height: 100%;
  display: block;
}
.insta {
  background: #1e252b;
  padding: 20px;
}
#instaHead {
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
.instaText {
  color: #ffffff;
}
.instaMid {
  display: -webkit-box;
  width: 310px;
  margin: auto;
  margin-top: 10px;
}
.instaIcon {
  padding: 5px 12px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  padding: 4px 20px;
}
#signUpButton {
  letter-spacing: 0.3px;
  background: #96a145;
  border: 1px solid #96a145;
  box-sizing: border-box;
  border-radius: 48px;
  color: white;
  text-decoration: none;
  padding: 12px 40px;
  border-radius: 40px;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 1000;
}

@media only screen and (max-width: 850px) {
  #instaHead {
    font-size: 24px;
  }
  .buttonContainer {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  /* #mapContainer {
    width: 90%;
    text-align: center;
  } */
  .passMargin {
    margin-left: 16px;
  }
  .passTextRed {
    font-size: 16px;
    left: 16px;
    right: 16px;
  }
  .shopifyBox2 a {
    font-size: 16px;
  }
  .locationsWrap {
    height: 30vh;
  }
  .search {
    width: -webkit-fill-available;
    height: 48px;
    font-size: 16px;
    background-size: 16px;
  }
  .locationText {
    font-size: 16px;
    line-height: 24px;
  }
  .searchContainer {
    width: 90%;
  }
  .locationsWrap {
    width: 100%;
  }
  #homeImgContainer {
    background-image: url("../assets/HomeImages/homepage.png");
    /* height: 70vh; */
    overflow-y: hidden !important;
  }
  .home1,
  .home3 {
    font-size: 14px;
  }
  .home2 {
    padding-top: 14px;
    font-size: 48px;
    line-height: 48px;
  }
  .shopifyContainer {
    display: block;
    padding: 8px;
  }
  .shopifyWrap {
    width: -webkit-fill-available;
    margin-bottom: 8px;
    margin-top: 0px;
  }
  .shopifyWrap,
  .shopifyBox,
  .shopifyBox2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
  }
  .collectionDiv {
    padding: 0px;
  }
  .shopifyBox,
  .shopifyBox2 {
    margin: 0px 0px;
  }
  .shopifyBox2 {
    margin-top: 8px;
  }
  .passText,
  .passType {
    font-size: 16px;
  }
}
/* Galaxy fold */
@media only screen and (max-width: 345px) {
  .instaMid {
    width: 85%;
    margin: 0px;
  }
}
@media only screen and (max-width: 600px) {
  #overlay-card {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
  }
  .home2 {
    font-size: 30px;
  }
}
@media only screen and (max-height: 1000px) {
  .centered-home {
    margin-top: 0px;
  }
}
@media only screen and (max-height: 800px) {
  .centered-home {
    margin-top: 0px;
  }
}
@media only screen and (max-height: 1200px) {
  .locationsWrap {
    max-height: 30vh;
  }
}
@media only screen and (max-height: 900px) {
  .locationsWrap {
    max-height: 20vh;
  }
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
  #homeImgContainer {
    background-image: url("../assets/HomeImages/homepage.png");
  }
}
@media only screen and (max-width: 768px) {
  /* Make the filter menu scroll horizontally */
  #filter-menu {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* for smooth scrolling on iOS */
    justify-content: flex-start; /* Align items to the start */
  }
  /* Ensure each filter item doesn’t shrink and displays inline */
  .filter-item {
    flex: 0 0 auto;
    margin-right: 10px; /* add spacing if needed */
  }
  #above-passes {
    flex-direction: column;
    align-items: flex-start;
  }
  #above-passes2 {
    flex-direction: row-reverse;
  }
  #bottom-section {
    flex-direction: column;
  }
  #passes {
    width: 100%;
  }
  .park-card {
    min-width: 260px;
  }
  #mapContainer {
    width: 100%;
    height: 700px;
  }
}
@media only screen and (max-width: 425px) {
  #page-padding {
    margin: 1rem 1rem;
  }
  .filter-text {
    font-size: 14px;
  }
  .filter-item {
    width: 56px;
  }
  #filter-menu {
    padding: unset;
  }
  .subheading {
    font-size: 24px;
    width: 300px;
  }
  #location-num {
    font-size: 20px;
  }
  #legend {
    font-size: 20px;
    padding-left: 20px;
  }
  #above-passes2 {
    justify-content: flex-end;
  }
  #above-passes {
    margin: 0px;
  }
  .park-card {
    max-width: unset;
    width: 100%;
    min-height: 497px;
  }
  #mapContainer {
    width: 100%;
    height: 450px;
  }
  .centered-home {
    margin-left: 20px;
    align-items: center;
  }
  .home2 {
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 115.5%;
    width: 290px;
  }
  .home3 {
    margin-bottom: 20px;
  }
  .v-text-field.v-text-field--enclosed {
    width: 100%;
  }
}
@media only screen and (max-width: 375px) {
  .park-card {
    min-height: 468px;
  }
}
@media only screen and (max-width: 320px) {
  .park-card {
    min-height: 432px;
  }
}
/* bigger screens */
@media only screen and (min-width: 1600px) {
}
</style>
